import React from "react";
import { MdStars } from "react-icons/md";
import { PRODUCT_UNIT } from "../../constants/product";
import Button from "../../components/UIElements/Button";
import { LocationOnSharp } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { openOfferFormModal } from "../../redux/store/modalsSlice";
import { useDispatch } from "react-redux";

export default function Item(item) {
  const {
    imagesURL,
    pId,
    id,
    unitPrice,
    avgRating,
    bookingsCount,
    quantity,
    qRemark,
    origin,
  } = item;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/explore/" + id);
  };

  return (
    <section className="flex md:flex-row flex-col gap-6">
      <div className="flex flex-col items-center md:w-[40%]">
        <img
          src={imagesURL[0]}
          alt={pId + "-image-" + id}
          className="h-[80%] rounded-lg w-full transition-transform duration-400 hover:scale-105"
        />
        <Button
          size="sm"
          className="w-full mt-1 z-2"
          onClick={() => dispatch(openOfferFormModal(item))}
        >
          Book Now
        </Button>
      </div>
      <div className="w-full">
        <div className="text-green-700 text-[12px] leading-[16px] font-semibold mb-2">
          Rs {unitPrice.value} PER Litre
        </div>
        <div className="text-[16px] leading-[16px] font-semibold mb-2">
          {pId}
        </div>
        <div className="flex text-[12px] items-center my-1 text-gray-600 leading-[16px] mb-2">
          <MdStars className="text-black mr-1 h-4 w-4" />
          <p>
            {avgRating} ({bookingsCount} bookings)
          </p>
        </div>
        <div className="text-[12px] flex text-gray-600 leading-[16px]">
          <span className="font-semibold">Total {quantity.value} Litre</span>
        </div>
        <div class="border border-gray-200 border-dashed h-0 w-full my-2"></div>
        <div className="text-[12px] text-gray-600 leading-[16px] mt-1 flex items-center">
          <LocationOnSharp className="text-black mr-1" sx={{ fontSize: 16 }} />
          {origin.addressLine}
        </div>
        <div className="text-[12px] text-gray-600 leading-[16px] mt-2">
          {qRemark}
        </div>
        <Button mode="text" onClick={handleClick} className="mt-2" size="sm">
          View details
        </Button>
      </div>
    </section>
  );
}
