import { PRODUCTS } from "../constants/product";
import { getReverseGeo } from "../redux/api/location";
import { listAll, ref, getDownloadURL, getStorage } from "firebase/storage";
import { app } from "../firebase";
import { store } from "../redux/store";

export const getRandomBinary = () => {
  return Math.random() < 0.5 ? 0 : 1;
};

export const getOfferById = (offerId) => {
  const { listingOffers } = store.getState().offers;
  const allOffers = Object.values(listingOffers).reduce(
    (p, a) => [...p, ...a],
    []
  );
  const offer = allOffers.find((o) => o.id === offerId);
  return offer;
};

export const getQuoteById = (quoteId) => {
  const { RFQQuotes } = store.getState().quotes;
  const allQuotes = Object.values(RFQQuotes).reduce((p, a) => [...p, ...a], []);
  const quote = allQuotes.find((q) => q.id === quoteId);
  return quote;
};

export const getProductImagesURL = async (productId) => {
  const storage = getStorage(app);
  const productImgRes = await listAll(ref(storage, `products/${productId}`));
  const imagesURL = await Promise.all(
    productImgRes.items.map((ref) => getDownloadURL(ref))
  );
  return imagesURL;
};

export function getCategory(index) {
  const categories = Object.keys(PRODUCTS);
  return categories[index];
}

export function getCategoryFromPID(pid) {
  const categories = Object.keys(PRODUCTS);
  let category = "solid";
  categories.map((k) => {
    if (PRODUCTS[k].filter((p) => p.id === pid).length > 0) {
      category = k;
    }
  });
  return category;
}

export function getProductByID(pid) {
  const categories = Object.keys(PRODUCTS);
  let product = {};
  categories.map((k) => {
    PRODUCTS[k].map((p) => {
      if (p.id === pid) product = p;
    });
  });
  return product;
}

export function getImgURLById(pid) {
  const categories = Object.keys(PRODUCTS);
  let productIdx = 0,
    category = "solid";
  categories.map((k) => {
    PRODUCTS[k].map((p, idx) => {
      if (p.id === pid) {
        productIdx = idx + 1;
        category = k;
      }
    });
  });
  return `${category}/${productIdx}.png`;
}

export function getProductIdByTitle(title) {
  for (const state in PRODUCTS) {
    for (const item of PRODUCTS[state]) {
      if (item.title === title) {
        return item.id;
      }
    }
  }
  return null;
}

export function calculateDeliveryCharges(quantity, deliveryCharges) {
  for (const range of deliveryCharges) {
    if (quantity >= range.minQuantity && quantity <= range.maxQuantity) {
      return range.charges;
    }
  }
  return 0;
}

export function getSubcategory(categoryIndex, subCategoryIndex) {
  const categories = Object.values(PRODUCTS);
  return categories[categoryIndex][subCategoryIndex];
}

export function getUserFormPercentage(userData) {
  const OPTIONAL_KEYS = [
    "brochureURL",
    "profileURL",
    "companyDesc",
    "gst",
    "address",
  ];
  let completedFields = 0,
    totalFields = 0;
  Object.entries(userData).map(([key, value]) => {
    if (!OPTIONAL_KEYS.includes(key) && !!value) {
      completedFields += 1;
      totalFields += 1;
    }
    if (key === "gst" && userData?.userType === 1) {
      if (!!value) completedFields += 1;
      totalFields += 1;
    }
  });
  return (completedFields / totalFields) * 100;
}

export const checkIsFormChanged = (initialData, currentData) => {
  return JSON.stringify(initialData) !== JSON.stringify(currentData);
};

export const verifyString = (name, value) => {
  if (name === "phoneNumber") {
    if (value.length !== 10 || !/^[0-9]*$/.test(value)) {
      return { error: "Please enter a valid 10 digit mobile number." };
    }
  } else if (name === "otp") {
    if (value.length !== 6 || !/^[0-9]*$/.test(value)) {
      return { error: "Please enter a numeric 6 digit OTP." };
    }
  } else if (name === "password") {
    if (!/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/.test(value)) {
      return {
        error:
          "Password should be 6 character long and must contain a special character, a number.",
      };
    }
  }
  return { error: "" };
};

export function extractError(text) {
  if (!/auth/.test) return text;
  let str = text.split("/")[1].slice(0, -2).replaceAll("-", " ");
  str = str.replace("email", "Phone Number");
  return `${str[0].toUpperCase() + str.slice(1)}.`;
}

export const compareObjects = (a, b) => {
  if (a === b) return true;

  if (typeof a != "object" || typeof b != "object" || a == null || b == null)
    return false;

  let keysA = Object.keys(a),
    keysB = Object.keys(b);

  for (let key of keysA) {
    if (!keysB.includes(key)) return false;

    if (typeof a[key] === "function" || typeof b[key] === "function") {
      if (a[key].toString() != b[key].toString()) return false;
    } else {
      if (!compareObjects(a[key], b[key])) return false;
    }
  }

  return true;
};

export const loadscript = (scriptUrl) => {
  return new Promise((resolve) => {
    const scriptTag = document.createElement("script");
    scriptTag.src = scriptUrl;

    scriptTag.onload = () => {
      resolve(true);
    };

    scriptTag.onerror = () => {
      resolve(false);
    };

    document.body.appendChild(scriptTag);
  });
};

export function roundToDecimal(value, precision) {
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}

export async function getDirection({ origin, destination }) {
  if (!origin || !destination) return;
  const directionService = new window.google.maps.DirectionsService();
  const res = await directionService.route({
    origin,
    destination,
    travelMode: window.google.maps.TravelMode.DRIVING,
  });
  return res;
}

export function islocationEqual(loc1, loc2) {
  if (roundToDecimal(loc1.latitude, 1) === roundToDecimal(loc2.latitude, 1)) {
    if (
      roundToDecimal(loc1.longitude, 1) === roundToDecimal(loc2.longitude, 1)
    ) {
      return true;
    }
    return false;
  }
  return false;
}

const findName = (level, components) => {
  const temp = components.find((obj) => {
    return obj.types[0] && obj.types[0] === level;
  });
  return temp?.long_name;
};

export const getStructuredLocation = (place) => {
  if (!place)
    return {
      city: "",
      state: "",
      country: "",
      postalCode: "",
      addressLine: "",
      coords: {
        latitude: "",
        longitude: "",
      },
    };
  const { address_components, formatted_address, geometry } = place;
  const location = {
    city: address_components[0].long_name,
    state: findName("administrative_area_level_1", address_components) || "",
    postalCode: findName("postal_code", address_components) || "",
    addressLine: formatted_address,
    coords: {
      latitude:
        typeof geometry.location.lat === "function"
          ? geometry.location.lat()
          : geometry.location.lat,
      longitude:
        typeof geometry.location.lng === "function"
          ? geometry.location.lng()
          : geometry.location.lng,
    },
  };

  return location;
};

export const getDeviceLocation = async () => {
  if (!navigator.geolocation) return;
  try {
    const pos = await new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });
    const { latitude, longitude } = pos.coords;
    const loc = await getReverseGeo(latitude, longitude);
    localStorage.setItem("lastLocation", JSON.stringify(loc));
    return loc;
  } catch (error) {
    console.error("Error getting location:", error);
  }
};

export const getStoredLocation = async () => {
  try {
    const location = JSON.parse(localStorage.getItem("lastLocation"));
    if (location) return location;
  } catch (error) {
    console.log("error get stored location");
  }
};
