import React from "react";

const Banner = ({ className, imageURL }) => {
  return (
    <div className={`${className} md:px-14 px-4 mt-14`}>
      <div className="rounded-md w-full md:min-h-[400px] flex justify-center items-center">
        <img
          src={imageURL}
          alt={imageURL}
          className="border w-full h-full rounded-md transition-transform duration-300 transform hover:scale-105"
        />
      </div>
    </div>
  );
};

export default Banner;
