import { useEffect, useState } from "react";
import { Chip } from "@mui/material";
import StarRating from "../../components/StarRating";
import { PAYMENT_TERMS } from "../../constants/product";
import { getImgURLById, getProductByID } from "../../utils/helper";
import Button from "../../components/UIElements/Button";
import { useDispatch } from "react-redux";
import {
  openOfferFormModal,
  openQuoteFormModal,
} from "../../redux/store/modalsSlice";

export default function Info({ item, reviews, type = "listing" }) {
  const dispatch = useDispatch();
  const [selectedImage, setSelectedImage] = useState("");

  useEffect(() => {
    if (!item) return;
    setSelectedImage(item?.imagesURL?.[0]);
  }, [item]);

  function handleOrder() {
    if (type === "listing") {
      dispatch(openOfferFormModal(item));
    } else {
      dispatch(openQuoteFormModal(item));
    }
  }

  return (
    <div className="flex md:flex-row flex-col gap-6 justify-center items-center">
      <div className="md:w-[50%] h-[500px] flex gap-4">
        <div className="h-full flex flex-col gap-4 overflow-y-auto">
          {type === "rfq" && (
            <img
              src={"/" + getImgURLById(item?.pId)}
              alt="item thumbnail"
              className={`rounded-xl cursor-pointer w-full h-[100px] object-cover border border-black`}
            />
          )}
          {item?.imagesURL?.map((img, index) => (
            <img
              key={index}
              src={img}
              alt="item thumbnail"
              className={`rounded-xl cursor-pointer w-full h-[100px] object-cover border ${
                selectedImage === img ? "border-black" : "border-transparent"
              }`}
              onClick={() => setSelectedImage(img)}
            />
          ))}
        </div>
        <div className="h-full rounded-xl overflow-hidden">
          <img
            src={
              type === "listing"
                ? selectedImage
                : "/" + getImgURLById(item?.pId)
            }
            alt="Selected item image"
            className="rounded-xl w-full h-full object-cover"
          />
        </div>
      </div>
      <div className="md:w-[50%]">
        <h2 className="capitalize md:text-3xl text-xl mt-4">
          {`${item?.pId}`.replaceAll("_", " ")}
        </h2>
        {item?.unitPrice && (
          <h3 className="md:text-4xl text-2xl mt-2">{`₹${
            item?.unitPrice.value
          } Per ${getProductByID(item?.pId).unit}`}</h3>
        )}
        <span className="flex gap-4 mt-2">
          <StarRating star={item?.avgRating} />
          <p className="text-gray-400">{`${reviews.length} Reviews`}</p>
        </span>
        <div className="border border-gray-200 border-dashed h-0 w-full my-4"></div>
        <p>Quality Remark: {item?.qRemark}</p>
        <ul className="mt-4 border-t pt-4 !list-disc">
          <li className="flex md:flex-row flex-col gap-4">
            <p className="font-medium text-gray-900">
              {type === "listing" ? "Available" : "Needed"} Quantity:
            </p>
            <span className="capitalize text-gray-400">
              {item?.quantity?.value + " " + getProductByID(item?.pId).unit}
            </span>
          </li>
          <li className="flex md:flex-row flex-col gap-4">
            <p className="font-medium text-gray-900">Category:</p>
            <span className="capitalize text-gray-400">{item?.categoryId}</span>
          </li>
          <li className="flex md:flex-row flex-col gap-4">
            <p className="font-medium text-gray-900">
              {type === "listing" ? "Pickup" : "Drop"} Location:
            </p>
            <span className="max-w-[150px] truncate capitalize text-gray-400">
              {
                item?.[type === "listing" ? "origin" : "destination"]
                  ?.addressLine
              }
            </span>
          </li>
        </ul>
        <p className="mt-4 border-t pt-4">{PAYMENT_TERMS[item?.tacType]}</p>
        {type === "listing" && (
          <div className="mt-2 flex gap-2">
            <Chip label="Negotiable Price" variant="outlined" />
            <Chip label="GST Included" variant="outlined" />
            <Chip label="Delivery Not Applicable" variant="outlined" />
          </div>
        )}
        <Button className="mt-4 w-fit" onClick={handleOrder}>
          Create Order
          <span className="ml-2 text-sm">{`(${
            type === "listing" ? item?.bookingsCount : item?.quotesCount
          } ${type === "listing" ? "Bookings" : "Quotes"})`}</span>
        </Button>
      </div>
    </div>
  );
}
