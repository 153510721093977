import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Login from "./Login";
import Signup from "./Signup";
import Home from "./Home";
import AboutUs from "./AboutUs/AboutUs";
import ContactUs from "./ContactUs";
import OurTeam from "./OurTeam/OurTeam";
import Explore from "./Explore";
import Listing from "./Details/Listing";
import RFQ from "./Details/RFQ";
import PageNotFound from "./PageNotFound";
import Booking from "./Booking";
import PrivacyPolicy from "./PolicyPages/PrivacyPolicy";
import ShipPolicy from "./PolicyPages/ShipPolicy";
import ReturnPolicy from "./PolicyPages/ReturnPolicy";
import Partnership from "./Partnership/Partnership";
import TermPolicy from "./PolicyPages/TermPolicy";
import Blog from "./Blog";
import Blogs from "./Blogs";
import FAQ from "./FAQ";

import Dashboard from "./Dashboard";
import Profile from "./Dashboard/Profile";
import Orders from "./Dashboard/Orders";
import Payments from "./Dashboard/Payments";
import RFQs from "./Dashboard/RFQs";
import Listings from "./Dashboard/Listings";
import Addresses from "./Dashboard/Addresses";
import Billing from "./Dashboard/Billing";
import Offers from "./Dashboard/Offers";
import Quotes from "./Dashboard/Quotes";
import { fetchLocations, getCurrentLocation } from "../redux/api/location";
import { fetchAllListings, fetchNewListings } from "../redux/api/listings";
import { fetchAllRFQs, fetchNearByRFQs } from "../redux/api/RFQs";

function RootRoute() {
  const { user } = useSelector((s) => s.auth);
  const { current } = useSelector((s) => s.location);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCurrentLocation());
    dispatch(fetchLocations());
  }, []);

  useEffect(() => {
    if (!user || (user.isBuyer && user.isSeller)) {
      fetchAllListings(dispatch);
      fetchAllRFQs(dispatch);
    } else if (user.isBuyer) {
      fetchAllListings(dispatch);
    } else if (user.isSeller) {
      fetchAllRFQs(dispatch);
    }
  }, [user]);

  useEffect(() => {
    if (!current?.coords.latitude) return;
    if (!user || (user.isBuyer && user.isSeller)) {
      dispatch(fetchNewListings());
      dispatch(fetchNearByRFQs());
    } else if (user.isBuyer) {
      dispatch(fetchNewListings());
    } else if (user.isSeller) {
      dispatch(fetchNearByRFQs());
    }
  }, [current, user]);

  return (
    <Routes>
      <Route path="" element={<Home />} />
      <Route path="login" element={<Login />} />
      <Route path="signup" element={<Signup />} />
      <Route path="booking" element={<Booking />} />
      <Route path="our-team" element={<OurTeam />} />
      <Route path="blogs" element={<Blogs />} />
      <Route path="blogs/:id" element={<Blog />} />
      <Route path="faq" element={<FAQ />} />
      <Route path="explore" element={<Explore />} />
      <Route path="listing/:id" element={<Listing />} />
      <Route path="rfq/:id" element={<RFQ />} />
      <Route path="privacy" element={<PrivacyPolicy />} />
      <Route path="ship-policy" element={<ShipPolicy />} />
      <Route path="return-policy" element={<ReturnPolicy />} />
      <Route path="term-policy" element={<TermPolicy />} />
      <Route path="about-us" element={<AboutUs />} />
      <Route path="contact-us" element={<ContactUs />} />
      <Route path="partnership" element={<Partnership />} />
      <Route path="dashboard" element={<Dashboard />}>
        <Route path="profile" element={<Profile />} />
        <Route path="billing" element={<Billing />} />
        <Route path="orders" element={<Orders />} />
        <Route path="payments" element={<Payments />} />
        <Route path="rfqs" element={<RFQs />} />
        <Route path="myOffers" element={<Offers />} />
        <Route path="myQuotes" element={<Quotes />} />
        <Route path="listings" element={<Listings />} />
        <Route path="addresses" element={<Addresses />} />
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default RootRoute;
