import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { LocationOn } from "@mui/icons-material";

import { openAddressSearchsModal } from "../../redux/store/modalsSlice";
import ProductSearchBar from "../ProductSearchBar";
import Button from "../UIElements/Button";

export default function Navbar() {
  const { user } = useSelector((state) => state.auth);
  const { current: currentLocation } = useSelector((state) => state.location);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleAddress = () => {
    dispatch(openAddressSearchsModal());
  };

  return (
    <nav className="w-screen font-dmsans z-10 h-[80px] flex gap-4 justify-between items-center md:px-14 px-6 py-10 bg-white sticky top-0">
      <Link to="/">
        <img src="/images/fuelcab-logo.svg" className="md:h-[50px] h-[40px]" />
      </Link>
      <ul className="text-[200] flex items-center gap-8">
        <li>
          <div class="mx-auto flex w-full max-w-lg items-center justify-center">
            <div class="relative z-10 flex w-full cursor-pointer items-center overflow-hidden rounded-xl p-[2px]">
              <div class="animate-rotate absolute inset-0 h-full w-full rounded-full bg-[conic-gradient(#047857_20deg,transparent_120deg)]"></div>
              <div class="relative z-20 flex w-full rounded-[0.60rem]">
                <Link
                  to="/booking"
                  className="md:text-md text-sm text-gray-600 relative z-20 flex w-full rounded-[0.60rem] bg-white p-2"
                >
                  Diesel Booking
                </Link>
              </div>
            </div>
          </div>
        </li>
        <li className="hidden md:block">
          <Link
            to="/about-us"
            className="md:text-md text-sm group text-gray-600 transition duration-300"
          >
            About Us
            <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-gray-600"></span>
          </Link>
        </li>
      </ul>
      <div className="hidden md:flex gap-3 items-center border rounded-md py-3 px-4">
        <div
          className="flex items-center gap-2 cursor-pointer"
          onClick={handleAddress}
        >
          <LocationOn sx={{ color: "gray" }} />
          <p className="truncate text-black font-[200] max-w-[200px]">{`${
            currentLocation?.addressLine || "Permission Required."
          }`}</p>
        </div>
        <div className="border-r h-[1.5rem]" />
        <ProductSearchBar />
      </div>
      {user ? (
        <div className="hidden md:flex items-center gap-4">
          <span>Hi, {user.name}</span>
          <Button onClick={() => navigate("/dashboard")}>Dashboard</Button>
        </div>
      ) : (
        <Link
          to="/login"
          className="hidden md:block border px-8 py-3 text-black rounded-md hover:bg-gray-100"
        >
          Login
        </Link>
      )}
    </nav>
  );
}
