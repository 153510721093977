import { useDispatch, useSelector } from "react-redux";

import Button from "../../components/UIElements/Button";
import { openBankFormModal } from "../../redux/store/modalsSlice";
import { useNavigate } from "react-router-dom";

function Pair({ title, value, onClick = () => {} }) {
  return (
    <div className="cursor-pointer group" onClick={onClick}>
      <div className="flex gap-4">
        <h3 className="text-sm text-gray-400">{title}</h3>
        <span className="text-sm text-gray-400 hidden group-hover:inline">
          Edit
        </span>
      </div>
      <p className="text-xl">{value}</p>
    </div>
  );
}

export default function BankDetails() {
  const { user } = useSelector((state) => state.auth);
  const { all: locations } = useSelector((state) => state.location);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <section className="h-full bg-gray-50 p-8">
      <div className="grid gap-y-4 sm:grid-cols-2">
        <Pair
          title="Name"
          value={user.name}
          onClick={() => navigate("/dashboard/profile")}
        />
        <Pair
          title="Email"
          value={user.email}
          onClick={() => navigate("/dashboard/profile")}
        />
        <Pair
          title="Phone Number"
          value={user.phoneNumber}
          onClick={() => navigate("/dashboard/profile")}
        />
        <Pair
          title="Address (Permanent)"
          value={locations.find((l) => l.id === user.pAddressId)?.addressLine}
          onClick={() => navigate("/dashboard/addresses")}
        />
      </div>
      <div className="mt-8 rounded-md bg-white border p-4 px-6">
        <div className="border-b pb-4 mb-4 flex w-full justify-between items-center">
          <p className="font-medium text-xl">ACCOUNT DETAILS</p>
          <Button
            onClick={(e) => {
              e.preventDefault();
              dispatch(openBankFormModal());
            }}
          >
            {user.bankDetails?.acNumber ? "Edit" : "Add"}
          </Button>
        </div>
        {user.bankDetails?.acNumber ? (
          <div className=" flex items-center justify-between">
            <span>
              <p className="text-xs text-gray-400">Account Number</p>
              <strong className="block text-lg font-medium">
                {user.bankDetails?.acNumber}
              </strong>
            </span>
            <span>
              <p className="text-xs text-gray-400">IFSC Code</p>
              <strong className="block text-lg font-medium">
                {user.bankDetails?.ifsc}
              </strong>
            </span>
            <span>
              <p className="text-xs text-gray-400">Name</p>
              <strong className="block text-lg font-medium">
                {user.bankDetails?.hName}
              </strong>
            </span>
            <span>
              <p className="text-xs text-gray-400">Branch</p>
              <strong className="block text-lg font-medium">
                {user.bankDetails?.branch}
              </strong>
            </span>
          </div>
        ) : (
          <div className="">
            <p className="text-gray-400">No Account Added</p>
          </div>
        )}
      </div>
    </section>
  );
}
