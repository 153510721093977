import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import { getRelatedRFQs, getRFQ, getRFQReviews } from "../../redux/api/RFQs";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import { getProductByID } from "../../utils/helper";
import Review from "./Review";
import Company from "./Company";
import Info from "./Info";
import ItemList from "../../components/ItemList";

const Meta = ({ title, endpoint }) => {
  return (
    <Helmet>
      <title>{title} - FuelCab India</title>
      <meta
        name="description"
        content="Discover FuelCab, a leading provider of on-demand fuel delivery services. Learn about our mission, values, achievements, and global presence."
      />
      <meta
        name="keywords"
        content="FuelCab, on-demand fuel delivery, fuel management, fuel delivery services, mission, values, achievements, global presence"
      />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="robots" content="index, follow" />
      <script type="application/ld+json">
        {`{
            "@context": "https://schema.org",
            "@type": "Organization",
            url: "https://www.fuelcab.com/rfq/${endpoint}",
            name: "FuelCab",
            description:
              "FuelCab is a leading provider of on-demand fuel delivery services. We are dedicated to making fuel management easy and convenient for our customers.",
          }`}
      </script>
    </Helmet>
  );
};

export default function RFQ() {
  const [reviews, setReviews] = useState([]);
  const [relatedRFQs, setRealtedRFQs] = useState([]);
  const [rfq, setRfq] = useState(null);
  const navigate = useNavigate();
  const { id: rfqId } = useParams();

  useEffect(() => {
    if (!rfqId) return navigate("/", { replace: true });
    getRFQ(rfqId).then((rfq) => {
      if (rfq) setRfq(rfq);
      else navigate("/", { replace: true });
    });
  }, [rfqId]);

  useEffect(() => {
    if (!rfq) return;
    getRFQReviews(rfq.id).then(setReviews);
  }, [rfq]);

  useEffect(() => {
    if (!rfq) return;
    getRelatedRFQs(rfq.categoryId).then(setRealtedRFQs);
  }, [rfq]);

  function handleAddReview(r, avgRating) {
    setReviews((p) => [...p, r]);
    setRfq((p) => ({ ...p, avgRating }));
  }

  return (
    <>
      <Meta title={`${getProductByID(rfq?.pId).title}`} endpoint={rfqId} />
      <Navbar />
      <div className="font-dmsans md:p-12 p-6">
        <Info item={rfq} reviews={reviews} type="rfq" />
        <Company ownerId={rfq?.buyerId} />
        <Review
          type="rfq"
          item={rfq}
          reviews={reviews}
          onAddReview={handleAddReview}
        />
        <ItemList title="Related RFQs" items={relatedRFQs} showPrice />
      </div>
      <Footer />
    </>
  );
}
