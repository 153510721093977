import { useLoadScript } from "@react-google-maps/api";
import { LinearProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import { useEffect } from "react";

import ListingFormModal from "./components/Modals/ListingFormModal";
import AddressSearchModal from "./components/Modals/AddressSearchModal";
import ProductSelectorModal from "./components/Modals/ProductSelectorModal";
import OrderFormModal from "./components/Modals/OrderFormModal";
import BankFormModal from "./components/Modals/BankFormModal";
import AddressFormModal from "./components/Modals/AddressFormModal";
import QuoteFormModal from "./components/Modals/QuoteFormModal";
import RFQFormModal from "./components/Modals/RFQFormModal";
import OfferFormModal from "./components/Modals/OfferFormModal";
import FeedbackModal from "./components/Modals/FeedbackModal";
import ListModal from "./components/Modals/ListModal";
import DispatchFormModal from "./components/Modals/DispatchFormModal";
import TransactionFormModal from "./components/Modals/TransactionFormModal";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import SnackBar from "./components/SnackBar";
import { fetchUserMetaDeta } from "./redux/api/auth";
import RootRoute from "./pages";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./App.css";

const MAP_LIBRARIES = ["places"];

export default function App() {
  const auth = getAuth();
  const dispatch = useDispatch();
  const { isLoaded: isAuthLoaded } = useSelector((state) => state.auth);
  const { isLoaded: isMapLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAPS_KEY,
    libraries: MAP_LIBRARIES,
  });

  useEffect(() => {
    const unSubscribe = onAuthStateChanged(auth, (user) => {
      dispatch(fetchUserMetaDeta(user));
    });
    return unSubscribe;
  }, []);

  // set viewport hard coded
  useEffect(() => {
    const viewport = document.querySelector("meta[name=viewport]");
    viewport.setAttribute(
      "content",
      viewport.content + ", height=" + window.innerHeight
    );
  }, []);

  if (!isAuthLoaded || !isMapLoaded) {
    return (
      <div className="h-screen w-screen flex justify-center items-center flex-col">
        <img
          src="/images/fuelcab-logo.svg"
          alt="fuelcab-logo"
          className="h-[50px]"
        />
        <LinearProgress
          sx={{
            width: "20vw",
            mt: 4,
            height: 4,
            borderRadius: 10,
            color: "#1D523B",
          }}
          color="inherit"
        />
      </div>
    );
  }

  return (
    <main className="App">
      <ScrollToTop />
      <RootRoute />

      {/* ------------ modals ---------- */}
      <SnackBar />
      <AddressSearchModal />
      <FeedbackModal />
      <AddressFormModal />
      <ListingFormModal />
      <RFQFormModal />
      <QuoteFormModal />
      <OfferFormModal />
      <DispatchFormModal />
      <TransactionFormModal />
      <ListModal />
      <ProductSelectorModal />
      <OrderFormModal />
      <BankFormModal />
    </main>
  );
}
