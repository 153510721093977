import Input from "../../components/UIElements/Input";
import CheckBox from "../../components/UIElements/CheckBox";

export default function Inputs({
  step,
  form,
  handleInputChange,
  otpsend,
  firstInptRef,
  setForm,
}) {
  switch (step) {
    case 0:
      return (
        <>
          {!otpsend && (
            <Input
              placeholder="Enter your Phone Number"
              {...form.phoneNumber}
              required
              minLength={10}
              name="phoneNumber"
              ref={firstInptRef}
              style={{ fontSize: "14px" }}
              onChange={handleInputChange}
              title="Phone Number"
              type="tel"
              className="w-full"
              pattern="[0-9]{10}"
              containerProps={{ className: "mt-4" }}
            />
          )}
          {otpsend && (
            <Input
              placeholder="Enter OTP"
              {...form.otp}
              required
              minLength={6}
              name="otp"
              onChange={handleInputChange}
              title={
                <>
                  OTP{" "}
                  <span className="ml-1 text-gray-400">
                    ({form.phoneNumber.value})
                  </span>
                </>
              }
              containerProps={{ className: "mt-4" }}
            />
          )}
        </>
      );
    case 1:
      return (
        <>
          <Input
            placeholder="Enter your email"
            {...form.email}
            type="email"
            required
            name="email"
            onChange={handleInputChange}
            title="Email"
            containerProps={{ className: "mt-4" }}
          />
          <Input
            placeholder="Enter password"
            {...form.password}
            onChange={handleInputChange}
            title="Password"
            type="password"
            required
            minLength={6}
            name="password"
            containerProps={{ className: "mt-4" }}
          />
          <div className="flex flex-col mt-4">
            <p className="text-sm font-[500]">Service Type</p>
            <span className="flex gap-2">
              <CheckBox
                checked={form.isBuyer?.value}
                label={"Buyer"}
                onChange={() =>
                  setForm((p) => ({
                    ...p,
                    isBuyer: { value: !p.isBuyer.value, error: "" },
                  }))
                }
              />
              <CheckBox
                checked={form.seller?.value}
                label={"Seller"}
                onChange={() =>
                  setForm((p) => ({
                    ...p,
                    isSeller: { value: !p.isSeller.value, error: "" },
                  }))
                }
              />
            </span>
          </div>
        </>
      );
    case 2:
      return (
        <>
          <Input
            placeholder="Enter Name"
            required
            minLength={3}
            name="name"
            {...form.name}
            onChange={handleInputChange}
            title="Name"
            containerProps={{ className: "mt-4" }}
          />
          <Input
            placeholder="Enter GSTIN"
            required
            minLength={3}
            name="gst"
            {...form.gst}
            onChange={handleInputChange}
            title="Company GSTIN"
            containerProps={{ className: "mt-4" }}
          />
        </>
      );
    default:
      break;
  }
}
