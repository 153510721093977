import { useState } from "react";
import StarRating from "../../components/StarRating";
import Button from "../../components/UIElements/Button";
import { useSelector } from "react-redux";
import { createListingReview } from "../../redux/api/listings";
import { createRFQReview } from "../../redux/api/RFQs";

export default function Review({
  item,
  reviews,
  onAddReview,
  type = "listing",
}) {
  const { user } = useSelector((s) => s.auth);
  const [loading, setLoading] = useState(false);
  const [showReviewForm, setShowReviewForm] = useState(false);
  const [reviewForm, setReviewForm] = useState({ note: "", rating: 0 });

  const handleAddReview = async (e) => {
    e.preventDefault();
    if (!user) return alert("Login to review");
    const avgRating =
      (item.avgRating * reviews.length + reviewForm.rating) /
      (reviews.length + 1);
    setLoading(true);
    let r = {};
    if (type === "listing") {
      r = await createListingReview(
        item.id,
        {
          userName: user.name,
          userId: user.id,
          ...reviewForm,
        },
        avgRating
      );
    } else {
      r = await createRFQReview(
        item.id,
        {
          userName: user.name,
          userId: user.id,
          ...reviewForm,
        },
        avgRating
      );
    }
    onAddReview(r, avgRating);
    setLoading(false);
    setShowReviewForm(false);
  };

  return (
    <div className="mt-8 p-8 border rounded-md">
      <h2 className="text-2xl">Rating & Review</h2>
      <div class="gap-2 flex items-center">
        <StarRating star={item?.avgRating} />
        <p class="mt-1 text-sm font-medium text-gray-500">
          {item?.avgRating} out of 5
        </p>
      </div>
      <p class="mt-2 text-sm font-medium text-gray-500">
        {reviews.length} global ratings
      </p>
      <div class="border-t w-full my-4"></div>

      {reviews.map(({ userName, rating, note, createdAt }) => (
        <article>
          <div class="font-medium">
            <p>{userName}</p>
          </div>
          <StarRating star={rating} />
          <footer class="mt-2 text-sm text-gray-500 dark:text-gray-400">
            <p>
              Reviewed on{" "}
              <time datetime="2017-03-03 19:00">
                {createdAt.toDate()?.toLocaleDateString()}
              </time>
            </p>
          </footer>
          <p class="mt-2 mb-4 text-gray-500">{note}</p>
        </article>
      ))}

      <div className="mt-4">
        {!showReviewForm && (
          <Button onClick={() => setShowReviewForm(true)}>
            Write a review
          </Button>
        )}
        {showReviewForm && (
          <form className="mt-8">
            <h3 className="text-2xl">Give the rating.</h3>
            <StarRating
              editable
              star={reviewForm.rating}
              onChange={(rating) => {
                setReviewForm((p) => ({
                  ...p,
                  rating,
                }));
              }}
            />
            <div className="flex flex-col gap-2 mt-2">
              <label className="text-sm font-[500]" htmlFor="review-note">
                Note
              </label>
              <textarea
                minRows={3}
                id="review-note"
                name="review-note"
                value={reviewForm.note}
                placeholder="I like it."
                required
                onChange={(e) =>
                  setReviewForm((p) => ({
                    ...p,
                    note: e.target.value,
                  }))
                }
                className="mt-1 p-4 min-h-[70%] text-black text-sm border rounded-md outline-none"
              />
            </div>
            <Button
              onClick={handleAddReview}
              disabled={!reviewForm.note || loading}
              loading={loading}
              className="mt-2"
            >
              Add Review
            </Button>
          </form>
        )}
      </div>
    </div>
  );
}
