import { createSlice } from "@reduxjs/toolkit";

const modalsSlice = createSlice({
  name: "modals",
  initialState: {
    listingFormModal: { isOpen: false, listingId: "", pId: "" },
    RFQFormModal: { isOpen: false, RFQId: "", pId: "" },
    dispatchFormModal: { isOpen: false },
    offerFormModal: { isOpen: false, selectedListing: {} },
    quoteFormModal: { isOpen: false, selectedRFQ: {} },
    orderFormModel: { isOpen: false, product: null },
    transactionFormModal: { isOpen: false, paymentId: "" },
    listModal: { isOpen: false, type: "offers", listingId: "", RFQId: "" },
    feedbackModal: { isOpen: false },
    orderSuccessModal: { isOpen: false },
    profileChangeModal: { isOpen: false, profile: 0 },
    addressSearchModal: { isOpen: false },
    bankFormModal: { isOpen: false },
    addressFormModal: {
      isOpen: false,
      addressId: "",
      onSubmit: () => {},
    },
    productSelectorModal: {
      isOpen: false,
      singleSelect: false,
      type: "all",
      defaultValues: [],
      onSelect: () => {},
    },
    snackbar: { isOpen: false, message: "", type: "error", cb: () => {} },
  },
  reducers: {
    openSnackbar: (state, action) => {
      state.snackbar.isOpen = true;
      const { message, type, cb } = action.payload;
      state.snackbar.message = message;
      if (type) state.snackbar.type = type;
      if (cb) state.snackbar.cb = cb;
    },
    closeSnackbar: (state) => {
      state.snackbar.isOpen = false;
      state.snackbar.message = "";
      state.snackbar.type = "error";
      state.snackbar.cb = () => {};
    },
    closeAuthModal: (state) => {
      state.authModal.isOpen = false;
      state.authModal.callback = () => {};
    },
    openAuthModal: (state, { payload }) => {
      state.authModal.isOpen = true;
      if (payload) {
        state.authModal.callback = payload;
      }
    },
    resetAuthModal: (state) => {
      state.authModal = { isOpen: false, modalNumber: 0, callback: () => {} };
    },
    changeAuthModalNumber: (state, action) => {
      state.authModal.modalNumber = action.payload.modalNumber;
    },
    closeRFQFormModal: (state) => {
      state.RFQFormModal.isOpen = false;
      state.RFQFormModal.RFQId = "";
      state.RFQFormModal.pId = "";
    },
    openRFQFormModal: (state, action) => {
      state.RFQFormModal.isOpen = true;
      const { RFQId, pId } = action.payload;
      if (RFQId) state.RFQFormModal.RFQId = RFQId;
      if (pId) state.RFQFormModal.pId = pId;
    },
    closeListingFormModal: (state) => {
      state.listingFormModal.isOpen = false;
      state.listingFormModal.listingId = "";
      state.listingFormModal.pId = "";
    },
    openListingFormModal: (state, action) => {
      state.listingFormModal.isOpen = true;
      const { listingId, pId } = action.payload;
      if (listingId) state.listingFormModal.listingId = listingId;
      if (pId) state.listingFormModal.pId = pId;
    },
    closeProductSelectorModal: (state) => {
      state.productSelectorModal.isOpen = false;
      state.productSelectorModal.type = "all";
      state.productSelectorModal.onSelect = () => {};
      state.productSelectorModal.singleSelect = false;
      state.productSelectorModal.defaultValues = [];
    },
    openProductSelectorModal: (state, action) => {
      state.productSelectorModal.isOpen = true;
      const { onSelect, singleSelect, type, defaultValues } =
        action.payload || {};
      if (type) state.productSelectorModal.type = type;
      if (onSelect) state.productSelectorModal.onSelect = onSelect;
      if (singleSelect) state.productSelectorModal.singleSelect = singleSelect;
      if (defaultValues)
        state.productSelectorModal.defaultValues = defaultValues;
    },
    closeOrderFormModal: (state) => {
      state.orderFormModel.isOpen = false;
    },
    openOrderFormModal: (state, action) => {
      state.orderFormModel.isOpen = true;
      state.orderFormModel.product = action.payload;
    },
    openFeedbackModal: (state) => {
      state.feedbackModal.isOpen = true;
    },
    closeFeedbackModal: (state) => {
      state.feedbackModal.isOpen = false;
    },
    openAddressFormModal: (state, action) => {
      state.addressFormModal.isOpen = true;
      const { addressId, onSubmit } = action?.payload || {};
      if (addressId) state.addressFormModal.addressId = addressId;
      if (onSubmit) state.addressFormModal.onSubmit = onSubmit;
    },
    closeAddressFormModal: (state) => {
      state.addressFormModal.isOpen = false;
      state.addressFormModal.addressId = "";
      state.addressFormModal.onSubmit = () => {};
    },
    openTransactionFormModal: (state, action) => {
      state.transactionFormModal.isOpen = true;
      const paymentId = action?.payload;
      if (paymentId) state.transactionFormModal.paymentId = paymentId;
    },
    closeTransactionFormModal: (state) => {
      state.transactionFormModal.isOpen = false;
      state.transactionFormModal.paymentId = "";
    },
    openOrderSuccessModal: (state) => {
      state.orderSuccessModal.isOpen = true;
    },
    closeOrderSuccessModal: (state) => {
      state.orderSuccessModal.isOpen = false;
    },
    openProfileChangeModal: (state, action) => {
      state.profileChangeModal.isOpen = true;
      state.profileChangeModal.profile = action.payload;
    },
    closeProfileChangeModal: (state) => {
      state.profileChangeModal.isOpen = false;
      state.profileChangeModal.profile = 0;
    },
    openAddressSearchsModal: (state) => {
      state.addressSearchModal.isOpen = true;
    },
    closeAddressSearchModal: (state) => {
      state.addressSearchModal.isOpen = false;
    },
    openBankFormModal: (state) => {
      state.bankFormModal.isOpen = true;
    },
    closeBankFormModal: (state) => {
      state.bankFormModal.isOpen = false;
    },
    openDispatchFormModal: (state, action) => {
      if (!action.payload) return;
      state.dispatchFormModal.isOpen = true;
      state.dispatchFormModal.orderId = action.payload;
    },
    closeDispatchFormModal: (state) => {
      state.dispatchFormModal.isOpen = false;
    },
    openOfferFormModal: (state, action) => {
      state.offerFormModal.isOpen = true;
      const listing = action.payload;
      if (listing) state.offerFormModal.selectedListing = listing;
    },
    closeOfferFormModal: (state) => {
      state.offerFormModal.selectedListing = {};
      state.offerFormModal.isOpen = false;
    },
    openQuoteFormModal: (state, action) => {
      state.quoteFormModal.isOpen = true;
      const RFQ = action.payload;
      if (RFQ) state.quoteFormModal.selectedRFQ = RFQ;
    },
    closeQuoteFormModal: (state) => {
      state.quoteFormModal.selectedRFQ = {};
      state.quoteFormModal.isOpen = false;
    },
    openListModal: (state, action) => {
      state.listModal.isOpen = true;
      const { type, listingId, RFQId } = action.payload;
      if (type) state.listModal.type = type;
      if (listingId) state.listModal.listingId = listingId;
      if (RFQId) state.listModal.RFQId = RFQId;
    },
    closeListModal: (state) => {
      state.listModal.isOpen = false;
      state.listModal.type = "offers";
      state.listModal.RFQId = "";
      state.listModal.listingId = "";
    },
  },
});

export const {
  openTransactionFormModal,
  closeTransactionFormModal,
  openQuoteFormModal,
  closeQuoteFormModal,
  openDispatchFormModal,
  closeDispatchFormModal,
  openListModal,
  closeListModal,
  openOfferFormModal,
  closeOfferFormModal,
  openRFQFormModal,
  closeRFQFormModal,
  openBankFormModal,
  closeBankFormModal,
  closeAuthModal,
  openAuthModal,
  changeAuthModalNumber,
  resetAuthModal,
  closeListingFormModal,
  openListingFormModal,
  openOrderFormModal,
  closeOrderFormModal,
  openFeedbackModal,
  openProductSelectorModal,
  closeProductSelectorModal,
  openOrderSuccessModal,
  closeProfileChangeModal,
  openAddressFormModal,
  closeAddressFormModal,
  openProfileChangeModal,
  closeOrderSuccessModal,
  closeFeedbackModal,
  openSnackbar,
  closeSnackbar,
  openAddressSearchsModal,
  closeAddressSearchModal,
} = modalsSlice.actions;
export default modalsSlice.reducer;
