import { Modal } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { closeProductSelectorModal } from "../../../redux/store/modalsSlice";
import { PRODUCTS } from "../../../constants/product";
import Button from "../../UIElements/Button";
import ProductCard from "../../ProductCard";

export default function ProductSelectorModal() {
  const { isOpen, onSelect, singleSelect, type, defaultValues } = useSelector(
    (state) => state.modals.productSelectorModal
  );
  const [selectedProducts, setSelectedProducts] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setSelectedProducts(defaultValues);
  }, [defaultValues]);

  function handleSelect(id) {
    setSelectedProducts((p) => {
      if (p.includes(id)) {
        return [...p].filter((s) => s !== id);
      } else {
        return [...p, id];
      }
    });
    if (singleSelect) {
      onSelect && onSelect(id);
      dispatch(closeProductSelectorModal());
    }
  }

  function handleClose() {
    setSelectedProducts([]);
    dispatch(closeProductSelectorModal());
  }

  function handleDoneClick() {
    onSelect(selectedProducts);
    dispatch(closeProductSelectorModal());
  }

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <div className="font-dmsans bg-white lg:px-12 px-8 py-10 w-[80%] h-[80%] rounded-md overflow-y-scroll">
        <div className="flex justify-between items-center">
          <h2 className="md:text-4xl text-2xl text-black">
            Select Product{" "}
            <span className="capitalize text-gray-500">({type})</span>
          </h2>
          <span className="flex gap-2">
            <Button onClick={handleClose} className="w-fit gap-2" mode="text">
              Close
            </Button>
            {!singleSelect && (
              <Button
                className="w-fit gap-2"
                disabled={!selectedProducts.length}
                onClick={handleDoneClick}
              >
                Done <ArrowForward fontSize="small" />
              </Button>
            )}
          </span>
        </div>
        <p className="mt-1 text-gray-400 text-md">
          Select product to proceed further.
        </p>
        {Object.keys(PRODUCTS).map((k) => {
          if (type !== "all" && k !== type) return;
          return (
            <>
              <p className="mt-12 text-xl capitalize">{`${k} Products`}</p>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-10 text mt-6">
                {PRODUCTS[k].map(({ title, desc, id }, idx) => (
                  <ProductCard
                    onClick={() => handleSelect(id)}
                    title={title}
                    description={desc}
                    imgSrc={`/${`${k}`.toLocaleLowerCase()}/${idx + 1}.png`}
                    selected={selectedProducts.includes(id)}
                  />
                ))}
              </div>
            </>
          );
        })}
      </div>
    </Modal>
  );
}
