import React from "react";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import ItemList from "../../components/ItemList";
import Hero from "./Hero";
import Banner from "./Banner";
import RFQ from "./RFQ";
import Category from "./Category";

const Meta = () => {
  return (
    <Helmet>
      <title>FuelCab India - Online Doorstep Diesel Delivery</title>
      <meta
        name="description"
        content="Discover FuelCab, a leading provider of on-demand fuel delivery services. Learn about our mission, values, achievements, and global presence."
      />
      <meta
        name="keywords"
        content="FuelCab, on-demand fuel delivery, Doorstep Diesel Delivery, fuel management, fuel delivery services, mission, values, achievements, global presence"
      />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="robots" content="index, follow" />
      <script type="application/ld+json">
        {`{
            "@context": "https://schema.org",
            "@type": "Organization",
            url: "https://www.fuelcab.com",
            name: "FuelCab India",
            description:
              "FuelCab is a leading provider of on-demand fuel delivery services. We are dedicated to making fuel management easy and convenient for our customers.",
          }`}
      </script>
    </Helmet>
  );
};

const chooseRandomly = (listings, rfqs) => {
  return Math.random() > 0.5 ? listings : rfqs;
};

const Home = ({ user }) => {
  const {
    newListings,
    trendingListings,
    nearbyListings,
    featuredListings,
    solidListings,
    liquidListings,
    gasListings,
    FCIListings,
  } = useSelector((s) => s.listings);

  const {
    newRFQs,
    trendingRFQs,
    nearbyRFQs,
    featuredRFQs,
    solidRFQs,
    liquidRFQs,
    gasRFQs,
    FCIRFQs,
  } = useSelector((s) => s.RFQs);

  const renderItemList = (baseTitle, listings, rfqs) => {
    let items;

    if (!user || (user.isBuyer && user.isSeller)) {
      items = chooseRandomly(listings, rfqs);
    } else if (user.isBuyer) {
      items = listings;
    } else if (user.isSeller) {
      items = rfqs;
    }

    const typeLabel = items === rfqs ? "RFQs" : "Listings";
    return (
      <ItemList title={`${baseTitle} ${typeLabel}`} items={items} showPrice />
    );
  };

  return (
    <main className="font-dmsans">
      <Meta />
      <Navbar />
      <Hero />
      <Category />
      {renderItemList("Trending", trendingListings, trendingRFQs)}
      {renderItemList("New", newListings, newRFQs)}
      <Banner className="mb-8" imageURL="/banner/1.png" />
      {renderItemList("Solid", solidListings, solidRFQs)}
      <Banner className="mb-8" imageURL="/banner/2.png" />
      {renderItemList("Liquid", liquidListings, liquidRFQs)}
      <Banner className="mb-8" imageURL="/banner/3.png" />
      {renderItemList("Gas", gasListings, gasRFQs)}
      {renderItemList("Near by", nearbyListings, nearbyRFQs)}
      {renderItemList("Featured", featuredListings, featuredRFQs)}
      {renderItemList("FCI", FCIListings, FCIRFQs)}
      <RFQ />
      <Footer />
    </main>
  );
};

export default Home;
