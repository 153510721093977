import React from "react";
import { Carousel } from "react-responsive-carousel";
import LazyLoad from "react-lazyload";

import { Link } from "react-router-dom";

function Banner({ imageURL, href }) {
  return (
    <Link to={href} className="w-full md:min-h-[80vh]">
      <LazyLoad className="w-full h-full">
        <img
          src={imageURL}
          className="px-8 w-full h-full rounded-md"
          alt="banner-1"
          loading="lazy"
        />
      </LazyLoad>
    </Link>
  );
}

export default function Hero() {
  return (
    <section className="md:px-14 px-6 py-10 text-black">
      <div className="w-full border rounded-md">
        <Carousel
          showArrows={false}
          showStatus={false}
          showThumbs={false}
          autoPlay
          infiniteLoop
          interval={3000}
        >
          <Banner
            imageURL="/images/banner-1.png"
            href="https://play.google.com/store/apps/details?id=com.fuelcab.fuelcab&hl=en"
          />
          <Banner
            imageURL="/images/banner-2.png"
            href="https://play.google.com/store/apps/details?id=com.fuelcab.fuelcab&hl=en"
          />
          <Banner
            imageURL="/images/banner-3.png"
            href="https://play.google.com/store/apps/details?id=com.fuelcab.fuelcab&hl=en"
          />
        </Carousel>
      </div>
    </section>
  );
}
