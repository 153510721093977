import { useEffect, useState } from "react";
import { getUser } from "../../redux/api/auth";
import { FireTruck, LockClock } from "@mui/icons-material";

export default function Company({ ownerId }) {
  const [owner, setOwner] = useState(null);

  useEffect(() => {
    if (!ownerId) return;
    getUser(ownerId).then(setOwner);
  }, [ownerId]);

  if (!owner) return null;

  return (
    <div className="mt-8 p-8 border rounded-md">
      <h2 className="text-2xl">Company Details</h2>
      <div class="border-t w-full my-4"></div>
      <div className="mt-2 w-full flex justify-between gap-12">
        <div>
          <h3 className="text-xl">Info</h3>
          <span className="text-md text-gray-400 mt-4">
            {owner.description}
          </span>
          <h3 className="text-xl mt-6">History</h3>
          <span className="text-gray-400 text-md mt-4">{owner.history}</span>
        </div>
        <img
          src={owner.profileURL}
          width={150}
          height={150}
          className="rounded-xl"
          alt={owner.name}
        />
      </div>
      <div className="flex gap-8 mt-8">
        <div className="flex items-center gap-4">
          <span className="bg-[#1D523B] p-6 rounded-[50%]">
            <FireTruck fontSize="large" sx={{ color: "white" }} />
          </span>
          <span className="flex flex-col">
            <h4 className="font-medium text-2xl text-gray-900">Started</h4>
            <p className="text-gray-600">
              {owner.createdAt?.toDate()?.toLocaleDateString()}
            </p>
          </span>
        </div>
        <div className="flex items-center gap-4">
          <span className="bg-[#1D523B] p-6 rounded-[50%]">
            <LockClock fontSize="large" sx={{ color: "white" }} />
          </span>
          <span className="flex flex-col">
            <h4 className="font-medium text-2xl text-gray-900">
              Establishement Year
            </h4>
            <p className="text-gray-600">{owner.yoe}</p>
          </span>
        </div>
      </div>
    </div>
  );
}
