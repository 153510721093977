import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Pagination from "../../components/UIElements/Pagination";
import { format } from "date-fns";
import { fetchUserBookings } from "../../redux/api/bookings";

function Pair({ title, value, ext }) {
  return (
    <div className="flex flex-col items-start gap-2">
      <h3 className="text-sm text-gray-400">{title}</h3>
      <p className="max-w-[200px] truncate text-md">
        {value} {ext ? " " + ext : ""}
      </p>
    </div>
  );
}

function Card({ id, edd, destination, status, quantity, createdAt }) {
  return (
    <div
      className={`bg-white flex items-center w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 rounded-xl hover:shadow gap-3`}
    >
      <div className="h-full w-full flex md:flex-row flex-col gap-4 md:gap-0 justify-between md:items-center items-start">
        <Pair title="Destination Address" value={destination.addressLine} />
        <Pair title="Quantity" value={quantity} />
        <Pair
          title="Expected Delivery Date"
          value={
            typeof edd === "string"
              ? format(edd, "dd/MM/yyyy")
              : edd.toDate().toLocaleDateString()
          }
        />
        <Pair title="Status" value={status} />
        <Pair
          title="Created At"
          value={createdAt.toDate().toLocaleDateString()}
        />
      </div>
    </div>
  );
}

const PAGE_ITEM_COUNT = 5;
const FILTERS = ["all", "on going", "completed"];

export default function History() {
  const { userBookings } = useSelector((s) => s.bookings);
  const [filter, setFilter] = useState(FILTERS[0]);
  const [filteredBookings, setFilteredBookings] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUserBookings());
  }, []);

  useEffect(() => {
    const bookings = userBookings.filter((booking) => {
      if (filter === "on going") {
        return booking.status === 1;
      } else if (filter === "completed") {
        return booking.status === 2;
      }
      return true;
    });
    setFilteredBookings([...bookings]);
  }, [filter, userBookings]);

  return (
    <>
      <div className="font-dmsans h-full md:mx-12 mx-6 my-8">
        <h2 className="text-2xl font-semibold p-2 border-b">Booking History</h2>
        <div className="mt-4">
          {FILTERS.map((fName) => {
            return (
              <button
                key={fName}
                onClick={() => setFilter(fName)}
                className={`capitalize inline-flex whitespace-nowrap border-b-2 border-transparent py-2 px-3 text-sm transition-all duration-200 ease-in-out ${
                  filter === fName
                    ? "text-[#1D523B] border-b-[#1D523B] font-semibold"
                    : "text-gray-600 hover:border-b-[#1D523B] hover:text-[#1D523B]"
                }`}
              >
                {fName}
              </button>
            );
          })}
        </div>
        <div className="bg-gray-50 p-8 mt-6 flex flex-col gap-4">
          {filteredBookings.length ? (
            filteredBookings
              .slice(
                (currentPage - 1) * PAGE_ITEM_COUNT,
                currentPage * PAGE_ITEM_COUNT
              )
              .map((rfq) => {
                return <Card key={rfq.id} {...rfq} />;
              })
          ) : (
            <h4 className="text-xl">No Bookings Found.</h4>
          )}
        </div>
        {!!filteredBookings.length &&
          filteredBookings.length > PAGE_ITEM_COUNT && (
            <div className="p-8">
              <Pagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPages={filteredBookings.length / PAGE_ITEM_COUNT}
              />
            </div>
          )}
      </div>
    </>
  );
}
