import { useEffect, useState } from "react";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";

import { getProductByID } from "../../../utils/helper";
import Button from "../../../components/UIElements/Button";
import Pagination from "../../../components/UIElements/Pagination";
import { PAYMENT_TERMS, CHARGE_TYPES } from "../../../constants/product";
import { deleteListing } from "../../../redux/api/listings";
import {
  openListingFormModal,
  openListModal,
  openProductSelectorModal,
} from "../../../redux/store/modalsSlice";

function Pair({ title, value, ext }) {
  return (
    <div className="flex flex-col items-start gap-2">
      <h3 className="text-sm text-gray-400">{title}</h3>
      <p className="text-md">
        {value} {ext ? " " + ext : ""}
      </p>
    </div>
  );
}

function Card({
  id,
  pId,
  imagesURL,
  unitPrice,
  tCharges,
  gstCharges,
  avgRating,
  bookingsCount,
  createdAt,
  quantity,
  origin,
  qRemark,
  tacType,
  expiry,
}) {
  const { loading } = useSelector((s) => s.listings);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const product = getProductByID(pId);

  const handleEdit = () => {
    dispatch(openListingFormModal({ pId, listingId: id }));
  };

  const handleDelete = () => {
    dispatch(deleteListing(id));
  };

  const handleView = () => {
    dispatch(openListModal({ listingId: id, type: "offers" }));
  };

  return (
    <div id="accordion-collapse" data-accordion="collapse">
      <button
        type="button"
        onClick={() => setShow(!show)}
        className={`bg-white flex items-center gap-8 w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 ${
          show ? "rounded-t-xl" : "rounded-xl"
        } hover:shadow gap-3`}
        data-accordion-target="#accordion-collapse-body-1"
        aria-expanded={show}
        aria-controls="accordion-collapse-body-1"
      >
        <img src={imagesURL[0]} className="w-[100px] rounded-xl" />
        <div className="h-full w-full flex justify-between items-center">
          <Pair title="Product" value={product.title} />
          <Pair
            title="Quantity"
            value={quantity.value}
            ext={quantity.forMonths ? `(${quantity.forMonths} Months)` : ""}
          />
          <Pair title="Bookings" value={bookingsCount} />
          <Pair
            title="Expiry By"
            value={expiry.toDate().toLocaleDateString()}
          />
          <Pair
            title="Created At"
            value={createdAt.toDate().toLocaleDateString()}
          />
          <Pair title="Averate Rating" value={avgRating} />
        </div>
        {show ? (
          <ArrowDownward className="ml-auto" />
        ) : (
          <ArrowUpward className="ml-auto" />
        )}
      </button>
      {show && (
        <div
          id="accordion-collapse-body-1"
          aria-labelledby="accordion-collapse-heading-1"
          className="bg-white border border-t-0 rounded-b-xl p-5 grid grid-cols-3 gap-4"
        >
          <div className="col-span-2 flex gap-4 items-center">
            <Button onClick={handleView}>View Offers</Button>
            <Button onClick={handleEdit}>Edit</Button>
            <Button
              loading={loading}
              onClick={handleDelete}
              className="bg-red-400"
            >
              Delete
            </Button>
          </div>
          <div className="col-span-3">
            <p className="text-gray-400 text-sm">Images</p>
            <div className="mt-2 flex items-center gap-4">
              {imagesURL.map((url) => {
                return <img src={url} className="w-[100px] border-r pr-4" />;
              })}
            </div>
          </div>
          <Pair
            title="Price/Unit"
            value={unitPrice.value}
            ext={unitPrice.negotiable ? "(Negotiable)" : "(Non Negotiable)"}
          />
          <Pair
            title="Transportation Charges"
            value={"₹" + tCharges.value}
            ext={`(${CHARGE_TYPES[tCharges.type]})`}
          />
          <Pair
            title="GST"
            value={gstCharges.value + "%"}
            ext={`(${CHARGE_TYPES[gstCharges.type]})`}
          />
          <Pair title="Pickup Address" value={origin.addressLine} />
          <Pair title="Quality Remark" value={qRemark} />
          <Pair title="Terms & Conditions" value={PAYMENT_TERMS[tacType]} />
        </div>
      )}
    </div>
  );
}

const PAGE_ITEM_COUNT = 5;
const FILTERS = ["all", "live", "expired"];

export default function Listings() {
  const { userListings } = useSelector((s) => s.listings);
  const [filter, setFilter] = useState(FILTERS[0]);
  const [filteredListings, setFilteredListings] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    const today = new Date().getTime();
    const ls = userListings.filter((listing) => {
      const expiry = listing.expiry?.toDate()?.getTime();
      if (filter === "live") {
        return expiry > today;
      } else if (filter === "expired") {
        return expiry < today;
      }
      return true;
    });
    setFilteredListings([...ls]);
  }, [filter, userListings]);

  const handleCreate = () => {
    dispatch(
      openProductSelectorModal({
        singleSelect: true,
        onSelect: (pId) => {
          dispatch(openListingFormModal({ pId }));
        },
      })
    );
  };

  return (
    <div className="h-full bg-gray-50">
      <div className="flex flex-wrap items-center gap-4 bg-white py-2 px-3">
        {FILTERS.map((fName) => {
          return (
            <button
              key={fName}
              onClick={() => setFilter(fName)}
              className={`capitalize inline-flex whitespace-nowrap border-b-2 border-transparent py-2 px-3 text-sm transition-all duration-200 ease-in-out ${
                filter === fName
                  ? "text-[#1D523B] border-b-[#1D523B] font-semibold"
                  : "text-gray-600 hover:border-b-[#1D523B] hover:text-[#1D523B]"
              }`}
            >
              {fName}
            </button>
          );
        })}
        <Button onClick={handleCreate} size="md" className="ml-auto">
          Create Listing
        </Button>
      </div>
      <div className="p-8 space-y-4">
        {filteredListings.length ? (
          filteredListings
            .slice(
              (currentPage - 1) * PAGE_ITEM_COUNT,
              currentPage * PAGE_ITEM_COUNT
            )
            .map((listing) => {
              return <Card key={listing.id} {...listing} />;
            })
        ) : (
          <h4 className="text-xl">No Listing created.</h4>
        )}
      </div>
      {!!filteredListings.length &&
        filteredListings.length > PAGE_ITEM_COUNT && (
          <div className="p-8">
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={filteredListings.length / PAGE_ITEM_COUNT}
            />
          </div>
        )}
    </div>
  );
}
