import { StandaloneSearchBox } from "@react-google-maps/api";
import { useEffect, useRef, useState } from "react";
import { Modal } from "@mui/material";
import { State } from "country-state-city";

import { getStructuredLocation } from "../../../utils/helper";
import Dropdown from "../../UIElements/Dropdown/Dropdown";
import Input from "../../UIElements/Input";
import Button from "../../UIElements/Button";
import { useDispatch, useSelector } from "react-redux";
import { closeAddressFormModal } from "../../../redux/store/modalsSlice";
import { addOrUpdateLocation } from "../../../redux/api/location";

const formInintialData = {
  state: "",
  postalCode: "",
  city: "",
  addressLine: "",
};

export default function AddressFormModal() {
  const { onSubmit, addressId, isOpen } = useSelector(
    (s) => s.modals.addressFormModal
  );
  const { all: locations, loading } = useSelector((s) => s.location);
  const [formData, setFormData] = useState(formInintialData);
  const dispatch = useDispatch();
  const inputRef = useRef();

  useEffect(() => {
    if (!addressId) return;
    const address = locations.find((a) => a.id === addressId);
    setFormData({ ...address });
  }, [addressId]);

  function handleInputChange(e) {
    e.preventDefault();
    const { name, value } = e.target;
    setFormData((p) => ({ ...p, [name]: value }));
  }

  const handlePlaceChanged = async () => {
    const [place] = inputRef.current.getPlaces();
    const address = getStructuredLocation(place);
    setFormData({ ...formData, ...address });
  };

  const handleClose = () => {
    setFormData({ ...formInintialData });
    dispatch(closeAddressFormModal());
  };

  const handleSubmit = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(addOrUpdateLocation(formData));
    onSubmit(formData);
    handleClose();
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      sx={{ justifyContent: "center", alignItems: "center", display: "flex" }}
    >
      <form
        className="font-dmsans rounded-md bg-white px-8 py-6"
        onSubmit={handleSubmit}
      >
        <div className="flex justify-between items-center">
          <h1 className="text-primary-100 md:text-[2.4rem] text-[2rem] leading-[2.8rem] trailing-[1.5px] font-semibold">
            Address
          </h1>
          <Button loading={loading} type="submit">
            Save
          </Button>
        </div>
        <p className="text-[1rem] leading-[1.5rem] text-gray-600">
          Add a new address to your profile
        </p>
        <div className="mt-4 grid grid-cols-2 gap-4">
          <div className="col-span-2 flex flex-col w-full gap-[5px]">
            <label className="text-sm font-[500]">Address Line</label>
            <StandaloneSearchBox
              style={{ border: "10px" }}
              onLoad={(ref) => (inputRef.current = ref)}
              onPlacesChanged={handlePlaceChanged}
            >
              <Input
                type="text"
                inputRef={inputRef}
                placeholder="Enter Address Line"
                value={formData.addressLine}
                name="addressLine"
                required
                onChange={handleInputChange}
              />
            </StandaloneSearchBox>
          </div>
          <Input
            placeholder="Enter City"
            value={formData.city}
            name="city"
            required
            containerProps={{ className: "col-span-2" }}
            onChange={handleInputChange}
            label="City"
          />
          <div>
            <p className="text-sm font-[500]">State</p>
            <Dropdown
              units={State.getStatesOfCountry("IN").map((s) => s.name)}
              innerProperty="name"
              unit={formData.state}
              setUnit={(value) => {
                setFormData((p) => ({
                  ...p,
                  state: value,
                }));
              }}
            />
          </div>
          <Input
            placeholder="Enter Postal Code"
            value={formData.postalCode}
            name="postalCode"
            required
            onChange={handleInputChange}
            label="Postal Code"
          />
        </div>
      </form>
    </Modal>
  );
}
