import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import Input from "../../components/UIElements/Input";
import Button from "../../components/UIElements/Button";
import CheckBox from "../../components/UIElements/CheckBox";
import ProductCard from "../../components/ProductCard";
import ProfileImg from "../../components/ProfileImg";
import {
  changePassword,
  updateUser,
  updateUserProfilePicture,
} from "../../redux/api/auth";
import { checkIsFormChanged, getProductByID } from "../../utils/helper";
import { openProductSelectorModal } from "../../redux/store/modalsSlice";

const initilaFormData = {
  name: "",
  email: "",
  phoneNumber: "",
  yoe: "",
  description: "",
  history: "",
  isBuyer: false,
  isSeller: false,
  sProducts: [],
  bProducts: [],
};

const getUserProfileData = (user) => {
  let data = {};
  Object.keys(initilaFormData).map((k) => {
    if (!user[k]) return;
    data[k] = user[k];
  });
  return data;
};

export default function Profile() {
  const { user, loading } = useSelector((state) => state.auth);
  const [formData, setFormData] = useState(initilaFormData);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [isFormChanged, setIsFormChanged] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user) return;
    setFormData((p) => ({ ...p, ...getUserProfileData(user) }));
  }, [user]);

  useEffect(() => {
    if (!user) return;
    let oldData = getUserProfileData(user);
    setIsFormChanged(checkIsFormChanged(oldData, formData));
  }, [formData, user]);

  async function handleSubmit(e) {
    e.preventDefault();
    dispatch(updateUser(formData));
  }

  function handleInputChange(e) {
    e.preventDefault();
    const { name, value } = e.target;
    setFormData((p) => ({ ...p, [name]: value }));
  }

  function handleImgChange(files) {
    dispatch(updateUserProfilePicture(files[0]));
  }

  function handlePasswordChange() {
    dispatch(changePassword({ oldPassword, newPassword }));
  }

  function handleEditBuying() {
    dispatch(
      openProductSelectorModal({
        defaultValues: formData.bProducts,
        onSelect: (products) =>
          setFormData((p) => ({ ...p, bProducts: products })),
      })
    );
  }

  function handleEditSelling() {
    dispatch(
      openProductSelectorModal({
        defaultValues: formData.sProducts,
        onSelect: (products) =>
          setFormData((p) => ({ ...p, sProducts: products })),
      })
    );
  }

  return (
    <div className="overflow-hidden sm:bg-gray-50 sm:px-8">
      <form className="mt-4 gap-4 grid grid-cols-2" onSubmit={handleSubmit}>
        <div className="col-span-2 flex justify-between items-center mb-2">
          <h2 className="text-gray-400 text-xl">COMPANY DETAILS</h2>
          <Button type="submit" loading={loading} disabled={!isFormChanged}>
            Save
          </Button>
        </div>
        <ProfileImg
          className="col-span-2 w-fit"
          label="Profile Picture"
          onChange={handleImgChange}
          value={user?.profileURL}
          loading={loading}
        />
        <Input
          value={formData.name}
          label="Company Name"
          placeholder="Enter Company Name"
          name="name"
          onChange={handleInputChange}
        />
        <Input
          value={formData.email}
          label="Email"
          placeholder="Enter Company Email"
          name="email"
          onChange={handleInputChange}
        />
        <Input
          value={formData.phoneNumber}
          label="Phone Number"
          placeholder="Enter Phone Number"
          name="phoneNumber"
          onChange={handleInputChange}
        />
        <Input
          value={formData.yoe}
          label="Established Year"
          placeholder="Enter Year"
          name="yoe"
          onChange={handleInputChange}
        />
        <div className="col-span-2 flex flex-col gap-1">
          <label className="text-sm leading-[18px] font-[500] text-[#0D2620]">
            Service Type
          </label>
          <span className="flex gap-2">
            <CheckBox
              checked={formData.isBuyer}
              onChange={() =>
                setFormData((p) => ({ ...p, isBuyer: !formData.isBuyer }))
              }
              label={"Buyer"}
            />
            <CheckBox
              checked={formData.isSeller}
              onChange={() =>
                setFormData((p) => ({ ...p, isSeller: !formData.isSeller }))
              }
              label={"Seller"}
            />
          </span>
        </div>
        {formData.isBuyer && (
          <div className="col-span-2">
            <div className="flex justify-between items-center">
              <label className="text-sm leading-[18px] font-[500] text-[#0D2620]">
                Interested Buying Products
              </label>
              <Button mode="text" onClick={handleEditBuying}>
                Edit
              </Button>
            </div>
            <div className="mt-2 grid grid-cols-2 gap-6">
              {formData.bProducts.map((pid, idx) => {
                const p = getProductByID(pid);
                return (
                  <ProductCard
                    title={p.title}
                    description={p.desc}
                    imgSrc={`/solid/${idx + 1}.png`}
                    selected
                    disabled
                  />
                );
              })}
              {!formData.bProducts.length && (
                <p className="text-sm text-gray-400">No Products Selected!</p>
              )}
            </div>
          </div>
        )}
        {formData.isSeller && (
          <div className="col-span-2">
            <div className="flex justify-between items-center">
              <label className="text-sm leading-[18px] font-[500] text-[#0D2620]">
                Interested Selling Products
              </label>
              <Button mode="text" onClick={handleEditSelling}>
                Edit
              </Button>
            </div>
            <div className="mt-2 grid grid-cols-2 gap-6">
              {formData.sProducts.map((pid, idx) => {
                const p = getProductByID(pid);
                return (
                  <ProductCard
                    title={p.title}
                    description={p.desc}
                    imgSrc={`/solid/${idx + 1}.png`}
                    selected
                    disabled
                  />
                );
              })}
              {!formData.sProducts.length && (
                <p className="text-sm text-gray-400">No Products Selected.</p>
              )}
            </div>
          </div>
        )}
        <hr className="my-4 col-span-2" />
        <div className="flex flex-col gap-2">
          <label
            className="text-sm leading-[18px] font-[500] text-[#0D2620]"
            htmlFor="company-description"
          >
            Company Description
          </label>
          <textarea
            minRows={3}
            value={formData.description}
            onChange={handleInputChange}
            id="company-description"
            name="description"
            placeholder="Company Description"
            className="bg-white p-4 w-full text-black bg-gray-50 text-sm border rounded-md outline-none"
          />
        </div>
        <div className="flex flex-col gap-2">
          <label
            className="text-sm leading-[18px] font-[500] text-[#0D2620]"
            htmlFor="company-history"
          >
            Company History
          </label>
          <textarea
            minRows={3}
            value={formData.history}
            onChange={handleInputChange}
            id="company-history"
            name="history"
            placeholder="Company History"
            className="bg-white p-4 w-full text-black bg-gray-50 text-sm border rounded-md outline-none"
          />
        </div>
      </form>
      <hr className="my-8" />
      <div className="mb-10">
        <p className="text-xl">CHANGE PASSWORD</p>
        <p className="mt-2 text-gray-400">
          Make sure you have taken backup of your account in case you ever need
          to get access to your data. We will completely wipe your data. There
          is no way to access your account after this action.
        </p>
        <div className="mt-2 flex items-center gap-6">
          <Input
            label="Old Password"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            placeholder="Enter your old password"
          />
          <Input
            label="New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            placeholder="Enter your new password"
          />
        </div>
        <Button
          className="mt-2"
          loading={loading}
          disabled={!oldPassword || !newPassword}
          onClick={handlePasswordChange}
        >
          Change
        </Button>
      </div>
    </div>
  );
}
