import { ArrowBack, ArrowForward, ArrowLeft } from "@mui/icons-material";
import React from "react";

const Pagination = ({ currentPage, setCurrentPage, totalPages }) => {
  const nextPage = () => {
    if (currentPage === totalPages) {
      return null;
    } else {
      setCurrentPage((prev) => prev + 1);
    }
  };
  const multiPage = () => {
    if (currentPage + 2 === totalPages) {
      return null;
    } else {
      setCurrentPage((prev) => prev + 2);
    }
  };
  const prevPage = () => {
    if (currentPage === 1) {
      return null;
    } else {
      setCurrentPage((prev) => prev - 1);
    }
  };
  return (
    <div className="mt-auto py-4 items-center rounded-md text-white bg-[#1D523B] flex flex-col border mx-auto">
      <ul className="text-sm font-bold flex mx-auto gap-8 items-center">
        <li>
          <button onClick={prevPage}>
            <ArrowBack />
          </button>
        </li>
        <li className="flex items-center justify-center text-center w-8 h-8 rounded-full bg-white text-black">
          <button>{currentPage}</button>
        </li>
        <li className="hover:*:text-cyan">
          <button onClick={nextPage}>{currentPage + 1}</button>
        </li>
        <li className="hover:*:text-cyan ">
          <button onClick={multiPage}>...</button>
        </li>
        <li>
          <button onClick={nextPage}>
            <ArrowForward />
          </button>
        </li>
      </ul>
    </div>
  );
};

export default Pagination;
